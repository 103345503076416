var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ingredient-item d-flex align-center flex-start pa-2"},[_c('router-link',{staticClass:"ingredient-image-link",attrs:{"aria-label":`Vai all'ingrediente - ${_vm.ingredient.ebsnProduct.slug}`,"title":`Vai all'ingrediente - ${_vm.ingredient.ebsnProduct.slug}`,"to":_vm.ingredient.ebsnProduct
        ? {
            name: 'Product',
            params: { slug: _vm.ingredient.ebsnProduct.slug }
          }
        : {}}},[(_vm.ingredient.ebsnProduct && _vm.ingredient.ebsnProduct.mediaURL)?_c('img',{staticClass:"ingredient-image",attrs:{"alt":_vm.ingredient.ingredient,"src":_vm.ingredient.ebsnProduct.mediaURL}}):_vm._e()]),_c('div',{staticClass:"ingredient-descr px-3"},[_c('span',{staticClass:"d-block ingredient-name"},[_c('span',{staticClass:"font-weight-bold"},[(_vm.ingredient.amount && _vm.ingredient.amount > 0)?_c('span',[_vm._v(_vm._s(_vm.ingredient.amount))]):_vm._e(),_vm._v(" "+_vm._s(_vm.ingredient.unit))]),_vm._v(" "+_vm._s(_vm.ingredient.ingredient))])]),(
      _vm.ingredient.ebsnProduct &&
        _vm.ingredient.ebsnProduct.available &&
        _vm.ingredient.ebsnProduct.available > 0
    )?[_c('ProductQty',{key:_vm.key,staticClass:"ingredient-action",attrs:{"product":_vm.product,"item":_vm.item}})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }