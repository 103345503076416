<template>
  <div class="ingredient-item d-flex align-center flex-start pa-2">
    <router-link
      class="ingredient-image-link"
      :aria-label="`Vai all'ingrediente - ${ingredient.ebsnProduct.slug}`"
      :title="`Vai all'ingrediente - ${ingredient.ebsnProduct.slug}`"
      :to="
        ingredient.ebsnProduct
          ? {
              name: 'Product',
              params: { slug: ingredient.ebsnProduct.slug }
            }
          : {}
      "
    >
      <img
        class="ingredient-image"
        v-if="ingredient.ebsnProduct && ingredient.ebsnProduct.mediaURL"
        :alt="ingredient.ingredient"
        :src="ingredient.ebsnProduct.mediaURL"
      />
    </router-link>

    <div class="ingredient-descr px-3">
      <span class="d-block ingredient-name"
        ><span class="font-weight-bold"
          ><span v-if="ingredient.amount && ingredient.amount > 0">{{
            ingredient.amount
          }}</span>
          {{ ingredient.unit }}</span
        >
        {{ ingredient.ingredient }}</span
      >
      <!-- <template v-if="ingredient.ebsnProduct">
        <span class="d-block product-name">
          {{ ingredient.ebsnProduct.name }}
        </span>
        <span class="d-block prodcut-short-descr">
          {{ ingredient.ebsnProduct.shortDescr }}
        </span>
        <span class="d-block prodcut-description">
          {{ ingredient.ebsnProduct.description }}
        </span>
      </template> -->
    </div>
    <template
      v-if="
        ingredient.ebsnProduct &&
          ingredient.ebsnProduct.available &&
          ingredient.ebsnProduct.available > 0
      "
    >
      <!-- <v-btn
        v-if="!item"
        class="ingredient-action add-ingredients-btn"
        color="primary"
        :aria-label="
          `${$t('recipeDetail.addProductTitle', {
            amount: amountNormalized,
            type: ingredient.ebsnProduct.priceUnitDisplay
          })}`
        "
        tile
        elevation="0"
        @click="addProduct(ingredient.ebsnProduct, amountNormalized)"
      >
        <v-icon>$cart</v-icon>
      </v-btn> -->
      <!-- ignore normalized quantity -->
      <ProductQty
        :key="key"
        :product="product"
        :item="item"
        class="ingredient-action"
      />
    </template>
  </div>
</template>
<style lang="scss">
.ingredient-item {
  border-bottom: 1px solid var(--v-white-base) !important;
  .ingredient-image {
    width: 70px;
    height: 70px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      width: 70px;
      height: 70px;
    }
  }

  .add-ingredients-btn,
  .product-not-available,
  .product-qty {
    margin-left: auto;
    margin-right: 0px;
  }
}
</style>
<script>
import ProductQty from "@/components/product/ProductQty.vue";

import productMixin from "~/mixins/product";

import { mapGetters } from "vuex";

export default {
  name: "IngredientItem",
  mixins: [productMixin],
  components: {
    ProductQty
  },
  props: {
    ingredient: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      key: 0
    };
  },
  computed: {
    ...mapGetters({
      getItem: "cart/getItem"
    }),
    product() {
      return this.ingredient.ebsnProduct;
    },
    item() {
      this.key;
      let item = this.getItem({
        product: this.product
      });
      return item;
    },
    amountNormalized() {
      return this.ingredient.amount_normalized > 0
        ? this.ingredient.amount_normalized
        : 1;
    }
  },
  methods: {},
  mounted() {}
};
</script>
