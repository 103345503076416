<template>
  <div class="ingredient-list">
    <template v-if="ebsnIngredients && ebsnIngredients.length > 0">
      <h3 class="mt-4 mb-1">
        {{ $t("recipeDetail.ingredients") }}
      </h3>
      <div
        class="grey lighten-2"
        v-for="ingredient in ebsnIngredients"
        :key="ingredient.notes"
      >
        <IngredientItem :ingredient="ingredient" />
      </div>

      <div class="d-flex gap-3 flex-column flex-sm-row justify-end mt-3 mb-6">
        <v-btn
          outlined
          color="primary"
          class="add-all-to-favourites-btn"
          depressed
          @click="addAllToFavorites"
        >
          {{ $t("recipes.addItem") }}
        </v-btn>
        <v-btn
          color="primary"
          class="add-all-to-cart-btn"
          depressed
          @click="addAllToCart"
          >{{ $t("recipeDetail.addAllIngredientsBtn") }}</v-btn
        >
      </div>
    </template>
  </div>
</template>
<style lang="scss">
.ingredient-list {
}
</style>
<script>
import IngredientItem from "@/components/article/IngredientItem.vue";
import ListSelector from "~/components/ListSelector.vue";

import login from "~/mixins/login";

import ListService from "~/service/listService";
import ProductService from "~/service/productService";

import { mapActions } from "vuex";
import cartService from "~/service/cartService";

export default {
  name: "IngredientList",
  mixins: [login],
  components: {
    IngredientItem
  },
  props: {
    ingredients: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      ebsnIngredients: null
    };
  },
  methods: {
    ...mapActions({
      addProducts: "cart/addProducts"
    }),
    async addAllToCart() {
      if (await this.needLogin("addallrecipeingredients")) {
        if (await this.needAddress()) {
          if (await this.needTimeslot()) {
            let productsToAdd = [];
            for (const product of this.ebsnIngredients) {
              if (product.ebsnProduct && product.ebsnProduct.available > 0) {
                //TODO reset normalized amount to 0
                // productsToAdd.push({
                //   product: product.ebsnProduct,
                //   quantity: Math.max(
                //     product.amount_normalized,
                //     cartService.plus(product)
                //   )
                // });
                //ignore mormalize quantity
                productsToAdd.push({
                  product: product.ebsnProduct,
                  quantity: cartService.plus(product.ebsnProduct)
                });
              }
            }
            await this.addProducts({ products: productsToAdd });
          }
        }
      }
    },
    async addAllToFavorites() {
      if (await this.needLogin("addtofavouritesrecipeingredients")) {
        let vm = this;
        let res = await vm.$dialog.show(ListSelector, {
          waitForResult: true,
          fullscreen: vm.$vuetify.breakpoint.smAndDown,
          width: 300
        });
        if (res) {
          let productsToAdd = [];
          for (const product of this.ebsnIngredients) {
            if (product.ebsnProduct) {
              productsToAdd.push({
                product: product.ebsnProduct,
                quantity: Math.max(product.amount_normalized, 1)
              });
            }
          }
          await ListService.addProductsToList(res.listId, productsToAdd);
        }
      }
    },
    async fetchProductsIngredients() {
      let ingredientsIds = this.ingredients.map(ingredient => {
        return ingredient.notes;
      });
      let products = (await ProductService.getProductsByIds(ingredientsIds))
        ?.products;
      if (products) {
        this.ebsnIngredients = this.ingredients.map(ingredient => {
          let ebsnIngredient = products.find(
            ebsnProduct => ingredient.notes == ebsnProduct.productId
          );
          if (ebsnIngredient) {
            ingredient["ebsnProduct"] = ebsnIngredient;
          }
          return ingredient;
        });
      } else {
        this.ebsnIngredients = this.ingredients;
      }
    }
  },
  mounted() {
    this.fetchProductsIngredients();
  }
};
</script>
